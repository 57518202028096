export default function () {
  return {
    status: null,
    message: null,
    success: true,

    post() {
      this.status = "loading";

      const first_name = this.$el.querySelector(
        'input[name="first_name"]',
      ).value;
      const email = this.$el.querySelector('input[name="email"]').value;
      const referrer = this.$el.querySelector(
        'input[name="_wp_http_referer"]',
      ).value;
      const nonce = this.$el.querySelector(
        'input[name="newsletter_subscription_nonce"]',
      ).value;

      fetch("/wp/wp-admin/admin-ajax.php?action=newsletter_subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          first_name,
          email,
          _wp_http_referer: referrer,
          nonce,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.message = data.data.message;
          this.success = data.success;

          this.status = null;
        })
        .catch((error) => {
          this.message = error.message;
          this.success = false;

          this.status = null;
        });
    },
  };
}
